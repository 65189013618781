/* hero section */
.hero-section {
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4%;
  box-sizing: border-box;
  font-family: 'Georgia';
}

/* hero tagline */
.hero-tagline {
  font-family: sans-serif;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-align: center;
  padding-bottom: 2%; /* Adjusted padding for smaller screens */
}

.hero-tagline p {
  font-size: 4.5rem;
  margin-bottom: 0;
}

/* hero description */
.hero-description {
  font-family: sans-serif;
  /* font-weight: bold; */
  font-size: 1.5rem;
  text-align: center;
  margin-top: 1%; /* Adjusted margin for smaller screens */
}

/* Media Queries for responsiveness */
@media (max-width: 1200px) {
  .hero-tagline p {
    font-size: 3.5rem;
  }

  .hero-description {
    font-size: 1.2rem;
  }
}

@media (max-width: 992px) {
  .hero-tagline p {
    font-size: 3rem;
  }

  .hero-description {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .hero-tagline p {
    font-size: 2.5rem;
  }

  .hero-section {
    padding: 2%; /* Adjusted padding for smaller screens */
  }

  .hero-description {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .hero-tagline p {
    font-size: 2rem;
  }

  .hero-description {
    font-size: 1rem;
  }
}
