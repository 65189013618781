.project-card {
  position: relative;
  overflow: hidden;
  height: 90%; 
  border-radius: 10% !important;

}

.card-img-container {
  position: relative;
  transition: transform 0.3s ease-in-out;
  height: 100%;
  width: 100%;
}

.project-card:hover .card-img-container img {
  transform: scale(1.1); /* Adjust the scale factor as needed */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.project-card:hover .overlay {
  opacity: 1;
}

.project-card .card-img-container img {
  transition: transform 0.3s ease-in-out;

}

.card-title {
  max-width: 80%;
  text-align: center;
  font-size: 1.5em;
}

.icon-container {
  width: 30%;
  display: flex;
  justify-content: space-between;
}

.icon {
  cursor: pointer;
  font-size: 1.5em; /* Set the initial font size */
  transition: font-size 0.1s ease-in-out; 
}

.icon:hover {
  font-size: 2em; /* Adjust the size increase as needed */
}


.modal-content {
  text-align: center;
  background-color: transparent !important; /* Change background color to transparent */
  border-radius: 10%;
}

.modal-body {
  max-width: 100vw;
  max-height: 90vh;
  margin: auto;
  border-radius: 10%;
  background-color: transparent !important; /* Change background color to transparent */
}

.modal img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  border-radius: 10%;
}


/* Media Queries for CompanyProjects */
@media (max-width: 576px) {
  .col-md-4 {
    flex: 0 0 70%;
    margin: auto; /* Add margin:auto to center columns *  }

  .project-card {
    height: 70%; /* Allow height to adjust based on content */
  }

  .card-img-container img {
    transform: scale(1); /* Reset the scale for smaller screens */
  }


}
