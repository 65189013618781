/* HeadingComponent.css */
.header-container {
    box-sizing: border-box;
    padding-bottom: 1%;
    padding-top: 2%;
  }
  
  .title {
    font-size: 1em; /* Adjust the font size for the title */
    text-align: center;
  }
  
  .detail {
    font-family: sans-serif;
    text-align: center;
    font-size: 2em; /* Adjust the font size for the detail */
    /*text-shadow: 0 0 10px rgba(255, 255, 255, 0.7); /*Add a glow effect to the detail*/
    font-weight: bold;
    color: #eefcea;
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .title {
      font-size: 0.8em;
    }
  
    .detail {
      font-size: 1.5em;
    }
    .header-container {
      padding-bottom: 2%;
      padding-top: 6%;
    }
  }
  
  @media (max-width: 576px) {
    .title {
      font-size: 0.7em;
    }
  
    .detail {
      font-size: 1.2em;
    }

    .header-container {
      padding-bottom: 2%;
      padding-top: 6%;
    }

  }
  