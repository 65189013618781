/* Story Container */
.story-container {
  box-sizing: border-box;
  font-family: 'Georgia', serif;
  padding: 2rem; /* Use rem for padding */
  font-size: 1rem; /* Use rem for font size */
  line-height: 1.6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
}

/* Story Description */
.story-description {
  text-align: left;
  font-size: 1.125rem; /* Use rem for font size */
  color: #ffffff;
}

/* Story Image */
.story-image {
  text-align: right;
}

/* Media Queries for responsiveness */
@media (max-width: 62em) {
  /* Equivalent to 992px */
  .story-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .story-description {
    font-size: 1rem;
  }
}

@media (max-width: 48em) {
  /* Equivalent to 768px */
  .story-container {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .story-description {
    font-size: 0.875rem; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 36em) {
  /* Equivalent to 576px */
  .story-container {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .story-description {
    font-size: 0.75rem; /* Adjust font size for even smaller screens */
  }

  .story-image {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
