/* For WebKit-based browsers */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the thumb */
  border-radius: 6px; /* Radius of the thumb */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

/* Handle on drag */
::-webkit-scrollbar-thumb:active {
  background-color: #555; /* Color of the thumb when dragged */
}

/* For Firefox */
.App {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

body {
  font-family: 'Georgia';
  overflow-x: hidden;

}

@import "~bootstrap/dist/css/bootstrap.min.css";

body {
  /* background-color: #008000; */
  background-color: #006400;
  /* background-color: #265C00; */
  color: #eefcea; /* set the text color for the description */
}


.a {
  text-decoration: none;
}

.a:hover {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.floating-button {
  position: fixed;
  bottom: 50px;
  right: 50px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  width: 50px;
  height: 50px;
  /* text-align: center;
  line-height: 50px;
  font-size: 20px; */
}

/* .floating-button::before {
  content: '\2191';
} */