
.brick-colors-insight {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.insight-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 1em; 
}

.insight-item {
  /* text-align: right; */
  margin-right:0;
  margin-left:0;
  margin-bottom: 6.5em; 
}

.insight-icon {
  width: 4.5em; 
  height: 4.5em;
}

.insight-title {
  font-family: century-gothic sans-serif;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.insight-description {
  font-size: 1em;
}

.main-image {
  width: 100%;
  max-width: 22em; 
}

.brick-colors-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.brick-colors-row img {
  width: 6em; 
  height: 7.5em; 
  margin: 0;
  transition: transform 0.3s;
}

.brick-colors-row .col {
  padding: 0; /* removing the default padding */
}

.brick-colors-row img:hover {
  transform: scale(1.2); /* increase size on hover */
}

.brick-colors-insight {
  margin-left: -15px; 
  margin-right: -15px; 
}

.brick-colors-insight > .col {
  padding-left: 0;
  padding-right: 0;
}

.main-image-column {
  padding: 0;
}

@media (max-width: 768px) {
  .brick-colors-insight {
    flex-direction: column;
  }

  .main-image-column {
    padding-top: 2em;
  }
}

@media (max-width: 768px) {
  .brick-colors-insight {
    flex-direction: column;
  }

  .main-image-column {
    order: -1;
  }

  .left-insight-col,
  .right-insight-col {
    order: -1;
  }
}

/* sm */
@media (max-width: 768px) {
  .main-image {
      width: 0;
      height:0;
  }
}

