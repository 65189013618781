/* Social proof container */
.social-proof-container {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    background-color: transparent;
    flex-direction: column;
    align-items: center; 
}

/* Testimonial cards container */
.testimonial-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}



/* Individual testimonial card */
.testimonial-card {
    flex: 1; 
    max-width: 300px; 
    height: 310px;
    padding: 10px 20px;
    border-radius: 25px;
    background-color: #6bc282;
    margin: 0 30px; 
    box-sizing: border-box; 
    position:relative;
    overflow: hidden; 
    border: none;
    margin: 15px;
    border-radius: 25px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(73, 97, 61, 0.1);

}

/* Testimonial name */
.testimonial-card__name {
    font-size: 28px;
    margin-bottom: 5px;
    letter-spacing: 3px;
    font-style: italic;
    font-weight: 800;
    /* color: #00ff59; */
    color: white;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.7); /* Add a glow effect to the detail */

}

/* Testimonial text */
.testimonial-card__testimonial {
    font-size: 24px;
    color: #000000;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show before adding ellipsis */
    -webkit-box-orient: vertical;

}


/* Testimonial link */
.testimonial-card__link {
    position: absolute;
    font-size: 20px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 15px;   
    background-color: #04310e;
    color: white;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    text-decoration: none;
    justify-content: flex-start;
    top: auto;

}

/* Arrow icon styling */
.readstory-arrow {
    font-size: 35px;
    margin-left: 13px; 
    vertical-align: baseline;
}

/* .slick-dots {
    margin-top: 40px !important;
} */

/* hiding the default text for arrows */
.slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    display: none;
  }
  
  /* customised styles for the arrows */
  .slick-prev::before, .slick-next::before {
    font-size:1.5rem;
    color: #ffffff; /* arrow color */
  }
 
  
/* Collaboration logos container */
.collaborations {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px; 
    background-color:#ffffff;
    

}

/* Individual collaboration logo */
.collaboration-logos {
    width: 10rem; 
    height: auto; /* maintaining aspect ratio */
    object-fit: contain;
    margin: 2rem auto 2rem;
    /* filter: grayscale(100%); */
    /* transition: filter 0.1s cubic-bezier(0.4, 0.2, 0.2, 1); */
}

/* .collaboration-logos.colorized {
    filter: grayscale(0%);
  } */