/* Products Container */
.products-container {
  box-sizing: border-box;
  font-family: 'Georgia';
  padding: 4%;
  padding-top: 0;
}

/* Product Card */
.product-card {
  border-radius: 10% !important;
  min-height: 85%;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin: 10px; /* Add margin to create spacing between cards */
  box-sizing: border-box; /* Ensure that padding and border are included in the width and height */
}

.product-card:hover {
  transform: scale(1.05);
}

.product-card__image {
  width: 100%;
  height: auto;
  border-radius: 20px 20px 0 0;
}

.product-card__name {
  font-family: century-gothic sans-serif;
  font-weight: bold;
  font-size: 1.25rem !important; 
  line-height: 1.2;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  transition: color 0.3s ease-in-out;
}

.product-card__about {
  font-size: 0.75rem;
  line-height: 1.5;
  text-align: center;
  color: #000;
  margin-top: 10px;
}

.product-link {
  text-decoration: none;
  color: inherit;
}

.card {
  border: none;
  margin-bottom: 20px;
}

/* Media Queries */
@media (max-width: 1200px) {
  .product-card__name {
    font-size: 1rem !important; /* Decrease font size for larger screens */
  }
}

@media (max-width: 992px) {

  .product-card__image {
    width: 100%; /* Make the image responsive */
  }
}


@media (max-width: 576px) {
  .product-card {
    border-radius: 8%; 
    margin: 5px auto; /* Center the card horizontally */
    max-width: 60%;
    margin-bottom: 10%;
  }

}
