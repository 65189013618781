/* Footer Container */
.footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    padding: 20px 20px 0px 20px;
    letter-spacing: 0.5px;
    background-color: #252922;
    flex-direction: row;
    color: #ffffff;
    height: auto;
  }
  
  /* Left Section */
.left-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1% 20px 1% 1%;
  }
  
  /* logo */
  .left-section img {
    max-width: 67px;
    margin-bottom: 10px;
  }
  
  /* consistent margin to contact information */
  .contact-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .contact-info p {
    margin: 0;
  }
  
  .view-map {
    display: flex;
    margin-left:5%;
    align-items: center;
    color: #ffffff;
  }

  .view-map p {
    margin-top:5px;
  }
  
  
  .location-icon {
    margin-right: 5px;
    margin-top:-10px;

  }
  
  .social-links {
    flex: 3;
    display: flex;
    justify-content:center;
    margin-top: 22%;
    justify-content: center;
    flex-direction: row; 
    box-sizing: border-box;
    margin-top: auto;

  }
  
  .social-icon {
    font-size: 24px;
    color: #ffffff;
    margin-right:3%;
  }
  
/* Right Section */
.right-section {
  flex: 1;
  max-width: 300px;
  margin-top:3.5%;
  margin-right:4%;
}

.newletter {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.input-wrapper {
  display: flex;
  align-items: stretch; 

}

input {
  flex: 1;
  padding: 8px;
  box-sizing: border-box;
  background-color: transparent; 
  border: 1px solid #fff; 
  color: #fff; 

}

input::placeholder {
  color: #fff; 
}

button {
  background-color: transparent; 
  color: #fff; 
  padding: 8px 15px;
  border: 1px solid #fff; 
  cursor: pointer;
  flex: 0 0 auto;
  margin-left: -1px;
}
  
  /* Divider */
  .footer-divider {
    width: 100%;
    border: 1px solid #ffffff;
    margin-top:20px;
    margin-bottom:0;
  }
  
  /* Bottom Section */
  .bottom-section {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding:5px;
  }
  
  .footer-links a {
    color: #ffffff;
    text-decoration: none;
    margin-right: 10px;
    margin-left:10px;
  }
  
  /* Copyright */
  .copyright {
    text-align: right;
  }
  