.impact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  }
  
  .impact {
  font-size: 28px;
  font-weight: 700;
  margin: 0 10px;
  }
  
  .impact-analytics {
  display: flex;
  justify-content: space-between;
  gap: 120px; /* adding equal spacing between circles */
  flex-wrap: wrap; 
  }
  
  .analytics-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  }
  
  .circle {
  position: relative;
  width: 300px;
  height: 300px;
  border: 6px solid #00ff80;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:10px;
  margin-bottom:70px;
  cursor: pointer;
  overflow: hidden;
  flex-direction: column;
    box-shadow: 0 0 0.5px #00ff00, 0 0 1px #00ff00, 0 0 1.5px #00ff00; 

  }
  
  .circle-icon {
    color: #000; 
    transition: opacity 0.3s ease-in-out; 
  }

  .heading {
    /* position: absolute; */
    margin-top:2em;
    font-size: 17px;
    font-weight: 650;
    text-align: center;
    letter-spacing:2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.3s ease-in-out; 
    padding:1.5em;
  }

  .circle-icon
{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

}



  .impact-divider {
  margin: 20px;
  width: 100%;
  margin-top:15px;
  margin-bottom:15px;
  border: 1px solid #00ff00;
  /* box-shadow: 0 0 0.5px #00ff00, 0 0 1px #00ff00, 0 0 1.5px #00ff00;  */
  margin: 1rem auto; 
  }
  
  .analytic-value {
    font-size: 2em; 
    color: #00ff80; 
    text-shadow: 0 0 0.5px #00ff00, 0 0 1px #00ff00, 0 0 1.5px #00ff00; 
  }

.analytic-heading {
  font-size: 1.5em;
  /* font-weight: bold; */
  }

